<template>
  <div>
    <v-form @submit.prevent="submitResponse">
      <p style="text-align: justify">
        Two highly soluble salts,
        <chemical-latex content="AB2" />
        and
        <chemical-latex content="C3D," />
        are mixed together in an Erlenmeyer flask. The solution is prepared such that
        <chemical-latex content="[AB2]=" />
        <number-value :value="concAB2" unit="\text{M}" />
        and
        <chemical-latex content="[C3D]=" />
        <number-value :value="concC3D" unit="\text{M.}" />
        Note that each of these species are hypothetical and do not represent actual elements from
        the periodic table.
      </p>

      <p class="mb-5">
        Considering the
        <stemble-latex content="$\text{K}_\text{sp}$" />
        values for
        <chemical-latex content="A3D2" />
        and
        <chemical-latex content="CB" />
        are
        <number-value :value="KspA3D2" />
        and
        <number-value :value="KspBC" />
        , respectively, answer each of the questions below.
      </p>

      <p class="mb-2">
        a) What is the value of
        <stemble-latex content="$\text{Q}_\text{sp}$" />
        for the salt
        <chemical-latex content="A3D2?" />
      </p>

      <calculation-input
        v-model="inputs.QspA3D2"
        class="mb-5"
        prepend-text="$\text{Q}_\text{sp}:$"
        :disabled="!allowEditing"
      />

      <p class="mb-2">
        b) What is the value of
        <stemble-latex content="$\text{Q}_\text{sp}$" />
        for the salt
        <chemical-latex content="CB?" />
      </p>

      <calculation-input
        v-model="inputs.QspBC"
        class="mb-5"
        prepend-text="$\text{Q}_\text{sp}:$"
        :disabled="!allowEditing"
      />

      <p class="mb-0">
        c) Which, if either, of these salts would precipitate from the solution based on the
        concentrations of each of the ions in solution?
      </p>

      <v-radio-group v-model="inputs.precipitate" class="pl-8 mt-1 mb-n5" :disabled="!allowEditing">
        <v-radio
          v-for="option in options"
          :key="option.value"
          class="d-inline-block"
          :value="option.value"
        >
          <template #label>
            <stemble-latex :content="option.expression" />
          </template>
        </v-radio>
      </v-radio-group>
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import ChemicalLatex from '../displayers/ChemicalLatex';
import CalculationInput from '../inputs/CalculationInput';
import NumberValue from '../NumberValue';
import StembleLatex from '@/tasks/components/StembleLatex';

export default {
  name: 'Question174',
  components: {
    StembleLatex,
    ChemicalLatex,
    CalculationInput,
    NumberValue,
  },
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      // Put a list of inputs or computed properties here to be sent for marking
      inputs: {
        QspA3D2: null,
        QspBC: null,
        precipitate: null,
      },
      options: [
        {
          expression: '$\\ce{A3D2 }\\text{ would precipitate}$',
          value: 'A3D2',
        },
        {expression: '$\\ce{CB }\\text{ would precipitate}$', value: 'BC'},
        {
          expression: '$\\text{Neither salt would precipitate}$',
          value: 'Neither',
        },
        {expression: '$\\text{Both could precipitate}$', value: 'Both'},
      ],
    };
  },
  computed: {
    concAB2() {
      return this.taskState.getValueBySymbol('concAB2').content;
    },
    concC3D() {
      return this.taskState.getValueBySymbol('concC3D').content;
    },
    KspA3D2() {
      return this.taskState.getValueBySymbol('KspA3D2').content;
    },
    KspBC() {
      return this.taskState.getValueBySymbol('KspBC').content;
    },
  },
};
</script>
